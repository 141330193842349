<template>
  <div class="pagination">
    <div class="itemsPerPage">
      <span>{{ $t("rowsPerPage") }}</span>
      <span>
        <v-select
          v-model="itemsPerPage"
          dense
          :items="itemsPerPageOptions"
          outlined
          height="10"
          hide-details
          @change="onItemPerPageChange()"
        />
      </span>
    </div>
    <!--  -->
    <div class="page-btn">
      <div
        :class="page <= 1 ? 'disabled' : ''"
        @click="prePage"
      >
        <span>previous</span>
      </div>
      <div
        :class="page == 1 ? 'active' : ''"
        @click="onPageClick(1)"
      >
        <span>1</span>
      </div>
      <div v-if="page > 4">
        <i>. . .</i>
      </div>
      <!-- logic -->
      <div
        v-for="p in getPageArray(page, pageBtnRange)"
        :key="p"
        :class="p == page ? 'active' : ''"
      >
        <span @click="onPageClick(p)">{{ p }}</span>
      </div>
      <!-- logic -->
      <div v-if="page <= pageBtnRange - 4">
        <i>. . .</i>
      </div>
      <div
        v-if="pageBtnRange != 1"
        :class="page == pageBtnRange ? 'active' : ''"
        @click="onPageClick(pageBtnRange)"
      >
        <span>{{ pageBtnRange }}</span>
      </div>
      <div
        :class="pageBtnRange == page ? 'disabled' : ''"
        @click="nextPage"
      >
        <span>next</span>
      </div>
    </div>
    <!--  -->
  </div>
</template>
<script>
export default {
  props: {
    subScreen: {
      type: Number,
      default() {
        return 0;
      },
    },
    activePage: {
      type: Number,
      default() {
        return 0;
      },
    },
    perPage: {
      type: Number,
      default() {
        return 0;
      },
    },
    totalItems: {
      type: Number,
      default() {
        return 0;
      },
    },
    lastPage: {
      type: Number,
      default() {
        return 0;
      },
    },
    perPageOptions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 20,
      //
      // itemsPerPageOptions: [20, 50, 100],
    };
  },
  computed: {
    itemsPerPageOptions() {
      if (this.perPageOptions.length > 0) {
        return this.perPageOptions;
      } else {
        return [20, 50, 100];
      }
    },
    pageBtnRange() {
      return this.lastPage;
    },
  },
  watch: {
    activePage(v) {
      this.page = v;
    },
    perPage(v) {
      this.itemsPerPage = v;
    },
  },
  mounted() {
    this.page = this.activePage;
    this.itemsPerPage = this.perPage;
  },
  methods: {
    getPageArray(activePage, totalPage) {
      const range = 2;
      const arr = [];
      //   skip 1 and Last Page
      for (let i = 2; i < totalPage; i++) {
        arr.push(i);
      }

      //   Now Take out 2  before and after from select Page

      return arr.filter(
        (r) => r - range <= activePage && r + range >= activePage
      );
    },
    onItemPerPageChange() {
      this.page = 1;
      this.emitData();
    },
    prePage() {
      if (this.page > 1) {
        this.page--;
        this.emitData();
      }
    },
    nextPage() {
      if (this.page < this.pageBtnRange) {
        this.page++;
        this.emitData();
      }
    },
    onPageClick(val) {
      this.page = val;
      this.emitData();
    },
    emitData() {
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
      };
      this.$emit("change", params);
    },
  },
};
</script>
<style lang="sass" scoped>
.pagination
    user-select: none !important
    display: flex
    flex-wrap: wrap
    gap: 1.5rem
    justify-content: space-between
    align-items: center
    margin-bottom: 1rem
    .itemsPerPage
        display: flex
        align-items: center
        gap: 1rem


    .page-btn
        font-size: 12px
        display: flex
        gap: 0.2rem
        // span:not(:last-child)
        div
            display: flex
            gap: 0.2rem
            align-items: flex-end

            i
                font-weight: 600
                align-items: flex-end
                padding: 0 0.5rem

            span
                background: #dddddd
                border: 1px solid #dddddd
                cursor: pointer
                padding: 0.5rem 1rem
                border-radius: 5px
                transition: 0.5s all ease
                &:hover
                    background: #20a390
                    border: 1px solid #20a390
                    color: white
        .active
            span
                background: #20a390
                border: 1px solid #20a390
                color: white !important
                font-weight: 600

        .disabled
            span
                background: grey !important
                color: white !important
                pointer-events: none

:deep .v-select
    width: 80px
    // height: 50px
    .v-input__control
        background: white !important
        font-size: 12px !important

// @media only screen and (max-width:950px)
//     .pagination
//         flex-direction: column
//         align-items: start
//         gap: 1rem
</style>
